<template>
  <div>
    <v-row
      justify="center"
      class="mt-4"
      v-if="publicdata.wettkampf && !publicdata.loading"
    >
      <v-col cols="auto" class="text-center">
        <v-img width="50px" :src="publicdata.verein.verein.logo" />
      </v-col>
      <v-col cols="auto" align-self="center">
        <h3 class="white--text">{{ publicdata.verein.verein.name }}</h3>
        <h2 class="white--text">{{ publicdata.wettkampf.name }}</h2>
      </v-col>
      <v-col cols="12" class="text-center mb-0" v-if="!zuschauerdaten">
        <v-divider class="mt-2 mb-4"></v-divider>

        <h4 v-if="publicdata.wettkampf.beginn.datum > timer.datum">
          VORANMELDUNG ZUM WETTKAMPF
        </h4>
        <h2
          v-if="
            publicdata.wettkampf.beginn.datum <= timer.datum &&
            publicdata.wettkampf.ende.datum >= timer.datum
          "
        >
          Sportler CheckIn
        </h2>
        <h2 v-if="publicdata.wettkampf.disziplinen.length > 0">SCHRITT 1</h2>
        <h4 class="font-weight-bold text-uppercase">
          Bitte gebe deine Kontaktdaten ein:
        </h4>
        <v-divider class="mt-4"></v-divider>
      </v-col>
      <v-col cols="12" v-if="!zuschauerdaten">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row justify="center">
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                label="Vorname"
                :disabled="user.data && !edit"
                :rules="[rules.required, rules.name]"
                v-model="v.vorname"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                label="Nachname"
                :disabled="user.data && !edit"
                :rules="[rules.required, rules.name]"
                v-model="v.name"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                label="Strasse + Nr."
                :disabled="user.data && !edit"
                :rules="[rules.required, rules.street, rules.streetnr]"
                v-model="v.adresse"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-text-field
                filled
                rounded
                label="Postleitzahl"
                :disabled="user.data && !edit"
                :rules="[rules.required, rules.plz]"
                v-model="v.plz"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                filled
                rounded
                :disabled="user.data && !edit"
                label="Ort"
                :rules="[rules.required, rules.name]"
                v-model="v.ort"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                :disabled="user.data && !edit"
                label="Nation"
                :rules="[rules.required, rules.name]"
                v-model="v.nation"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                :disabled="user.data && !edit"
                label="Telefon"
                :rules="[rules.required, rules.telefon]"
                v-model="v.telefon"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-text-field
                filled
                rounded
                :disabled="user.data && !edit"
                label="E-Mail"
                :rules="[rules.required, rules.email]"
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="11" class="text-center mb-5" v-if="user.data">
              <v-btn
                rounded
                :color="template.colors.primary"
                @click="edit = !edit"
              >
                {{ edit ? 'Änderung abbrechen' : 'Meine Kontaktdaten ändern' }}
              </v-btn>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-select
                filled
                rounded
                label="Aktivität"
                :items="aktivitaeten"
                :rules="[rules.required]"
                v-model="v.aktivitaet"
              ></v-select>
            </v-col>
            <v-col cols="11" class="my-5">
              <ggswitches
                :geimpft="false"
                :getestet="false"
                :genesen="false"
                :datum="false"
                :public="true"
                @valid="validate_ggg($event)"
                @change="get_ggg_data($event)"
              ></ggswitches>
            </v-col>
            <v-col cols="11" class="py-0 mb-4">
              <v-divider class="my-3"></v-divider>
              <span class="font-weight-light white--text">
                Einverständniserklärung zur Erhebung personenbezogener Daten
                <br />
                <br />
                Der Verein ist im Rahmen der Coronaschutzverordnung
                (CoronaSchVO) des Landes Nordrhein-Westfalens (NRW) während der
                Corona-Pandemie zur Erhebung von personenbezogenen Kontaktdaten
                und Aufenthaltszeiträumen verpflichtet. Die Erhebung der Daten
                erfolgt gemäß § 4a Abs. 1 i.V.m. Abs. 2 CoronaSchVO NRW in der
                derzeit gültigen Fassung.
              </span>
            </v-col>
            <v-col cols="11" class="py-0">
              <v-checkbox
                v-model="checkbox"
                label="Hiermit bin ich mit der Speicherung meiner Daten im Rahmen der Corona-Schutzverordnung einverstanden."
              ></v-checkbox>
            </v-col>
            <v-col
              cols="11"
              class="py-0 text-center"
              v-if="publicdata.wettkampf.disziplinen.length > 0"
            >
              <v-btn
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                rounded
                large
                :disabled="!checkbox || !ggg_valid"
                @click="
                  save_zuschauerdaten()
                  scrollTop()
                "
              >
                WEITER
              </v-btn>
            </v-col>
            <v-col
              cols="11"
              class="py-0 text-center"
              v-if="
                publicdata.wettkampf.disziplinen.length == 0 &&
                publicdata.wettkampf.beginn.datum > timer.datum
              "
            >
              <v-btn
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                :disabled="!checkbox"
                x-large
                rounded
                :loading="loading"
                @click="
                  save_zuschauerdaten()
                  wettkampf_anmeldung()
                "
              >
                Jetzt anmelden
              </v-btn>
            </v-col>
            <v-col
              cols="11"
              class="py-0 text-center"
              v-if="
                publicdata.wettkampf.disziplinen.length == 0 &&
                publicdata.wettkampf.beginn.datum <= timer.datum &&
                publicdata.wettkampf.ende.datum >= timer.datum
              "
            >
              <v-btn
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                :disabled="!checkbox"
                x-large
                rounded
                :loading="loading"
                @click="
                  save_zuschauerdaten()
                  check_in()
                "
              >
                Jetzt anmelden
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col
        cols="12"
        class="text-center mb-0"
        v-if="
          zuschauerdaten &&
          publicdata.wettkampf.disziplinen.length > 0 &&
          !finished
        "
      >
        <v-divider class="mt-2 mb-4"></v-divider>
        <h4>SCHRITT 2</h4>
        <h2>
          {{ template.var.verband == 'DEU' ? 'GRUPPE(N)' : 'DISZIPLIN(EN)' }}
          WÄHLEN
        </h2>
        <h4 class="font-weight-bold text-uppercase">
          Bitte wähle die
          {{ template.var.verband == 'DEU' ? 'Gruppe(n)' : 'Disziplin(en)' }}
          aus, an denen du teilnehmen möchtest:
        </h4>
        <v-divider class="mt-4"></v-divider>
      </v-col>
      <v-col
        cols="12"
        class="text-center mt-0"
        v-if="publicdata.wettkampf && zuschauerdaten && !finished"
      >
        <v-card
          class="my-3 py-0 rounded-xl"
          :color="selection.includes(v) ? 'success' : 'grey darken-3'"
          v-for="(v, index) in publicdata.wettkampf.disziplinen"
          :key="index"
          @click="select(v)"
        >
          <v-row justify="center" class="py-0 my-0">
            <v-col cols="2" class="py-0" align-self="center">
              <v-card
                color="transparent"
                flat
                class="rounded-tl-xl pl-0 py-0 ma-0"
              >
                <v-avatar rounded>
                  <v-icon large>
                    {{ selection.includes(v) ? 'mdi-check-bold' : 'mdi-medal' }}
                  </v-icon>
                </v-avatar>
              </v-card>
            </v-col>

            <v-col align-self="center" class="pb-0 text-left">
              <h4>
                {{ v.name }}
                <span class="font-weight-light" v-if="v.altersklasse">
                  ({{ v.altersklasse }})
                </span>
              </h4>
              <p>
                Am {{ parseDate(v.beginn.datum) }} um {{ v.beginn.uhrzeit }} Uhr
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        class="my-5 text-center"
        v-if="
          zuschauerdaten &&
          !finished &&
          publicdata.wettkampf.beginn.datum > timer.datum
        "
      >
        <v-btn
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          :disabled="selection.length == 0"
          x-large
          rounded
          :loading="loading"
          @click="wettkampf_anmeldung()"
        >
          Jetzt anmelden
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        class="my-5"
        v-if="
          zuschauerdaten &&
          !finished &&
          publicdata.wettkampf.beginn.datum <= timer.datum &&
          publicdata.wettkampf.ende.datum >= timer.datum
        "
      >
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-btn
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              :disabled="selection.length == 0 || testpflicht"
              x-large
              rounded
              :loading="loading"
              @click="check_in()"
            >
              Jetzt anmelden
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="finished">
        <v-card
          class="rounded-xl pa-4 text-center"
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text}`"
        >
          <h3 class="text-center text-uppercase">
            Anmeldung erfolgreich
          </h3>
          <v-divider light class="my-2"></v-divider>
          <p>
            Deine Anmeldung für den Wettkampf
            <span
              class="font-weight-bold"
              :style="`color: ${template.colors.primary};`"
            >
              {{ publicdata.wettkampf.name }}
            </span>
            war erfolgreich.
          </p>
          <v-btn
            large
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
            :to="`/wk/${publicdata.verein.alias}/${publicdata.wettkampf.id}`"
          >
            OK
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="publicdata.loading">
      <v-col cols="12" class="text-center mb-4" v-if="!publicdata.nodata">
        <v-progress-circular
          :color="template.colors.primary"
          circle
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col cols="12" class="text-center mb-4" v-if="publicdata.nodata">
        <h2>Seite nicht gefunden</h2>
        <p>
          Dein eingegebener Link scheint fehlerhaft zu sein. Bitte überprüfe
          deine Eingabe und versuche es erneut.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
import router from '../../routes/index'
//import firebase from 'firebase'
import ggswitches from './2G-Regel/switches.vue'

export default {
  name: 'Mein-Verein',
  components: {
    ggswitches,
  },
  data() {
    return {
      selection: [],
      selection_ids: [],
      valid: false,
      edit: false,
      checkbox: false,
      zuschauerdaten: '',
      disziplinen: [],
      email: '',
      v: {
        name: '',
        vorname: '',
        adresse: '',
        plz: '',
        ort: '',
        nation: '',
        telefon: '',
        aktivitaet: '',
        genesen: false,
        geimpft: false,
      },
      ggg: {
        getestet: false,
        geimpft: false,
        genesen: false,
      },
      ggg_valid: false,
      aktivitaeten: ['Sportler', 'Trainer', 'Kampfrichter', 'Orga-Team'],
      loading: false,
      finished: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
    testpflicht() {
      if (this.publicdata.verein) {
        if (this.publicdata.verein.hygienekonzept) {
          if (this.publicdata.verein.hygienekonzept.testpflicht) {
            if (!this.ggg.getestet && !this.ggg.geimpft && !this.ggg.genesen) {
              return true
            }
          }
        }
      }
      return false
    },
    testpflicht_required() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (tthis.publicdata.verein.hygienekonzept.testpflicht) {
          if (!this.publicdata.verein.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      return false
    },
    gg_regel() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (this.publicdata.verein.hygienekonzept.gg_regel) {
          return true
        }
      }
      return false
    },
    ggplus_regel() {
      if (this.publicdata.verein && this.publicdata.verein.hygienekonzept) {
        if (this.publicdata.verein.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      return false
    },
  },
  mounted() {
    if (!this.user) {
      this.zuschauerdaten = ''
      if (this.$route.params.sportstaette) {
        router.push(
          '/' +
            this.$route.params.verein +
            '/' +
            this.$route.params.sportstaette,
        )
      } else {
        router.push('/' + this.$route.params.verein)
      }
    } else {
      if (this.user.data) {
        this.v.vorname = this.user.data.vorname
        this.v.name = this.user.data.nachname
        if (this.user.zuschauerdaten) {
          this.v.adresse = this.user.zuschauerdaten.adresse
          this.v.plz = this.user.zuschauerdaten.plz
          this.v.ort = this.user.zuschauerdaten.ort
          this.v.nation = this.user.zuschauerdaten.nation
            ? this.user.zuschauerdaten.nation
            : 'Deutschland'
          this.v.telefon = this.user.zuschauerdaten.telefon
        }
        this.email = this.user.email
      }
      if (
        this.publicdata
          ? this.publicdata.wettkampf
            ? this.publicdata.wettkampf.beginn.datum <= this.timer.datum &&
              this.publicdata.wettkampf.ende.datum >= this.timer.datum
            : false
          : false
      ) {
        this.v.aktivitaet = 'Sportler'
      }
    }
  },
  methods: {
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.ggg = {
          getestet: daten.ggg.getestet || false,
          geimpft: daten.ggg.geimpft || false,
          genesen: daten.ggg.genesen || false,
          geburtsdatum: daten.geburtsdatum || false,
        }
      }
    },
    parseDate(datum) {
      var date = datum.split('-')
      return date[2] + '.' + date[1] + '.' + date[0]
    },
    scrollTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
    delete_person(index) {
      var i
      var hilf = this.weitere_personen
      this.weitere_personen = []
      for (i = 0; i < hilf.length; i++) {
        if (hilf[i] != hilf[index]) {
          this.weitere_personen.push(hilf[i])
        }
      }
    },
    select(id) {
      if (this.selection.includes(id)) {
        this.remove(id)
      } else {
        this.selection.push(id)
        this.selection_ids.push(id.id)
      }
    },
    addNull(val) {
      if (val < 10) {
        return '0' + val
      } else {
        return val
      }
    },
    remove(id) {
      var i
      var new_selection = []
      var new_selection_ids = []
      var new_types_selection = []
      for (i = 0; i < this.selection.length; i++) {
        if (this.selection[i] !== id) {
          new_selection.push(this.selection[i])
          new_selection_ids.push(this.selection[i].id)
        }
      }
      this.selection = new_selection
      this.selection_ids = new_selection_ids
    },
    save_zuschauerdaten() {
      if (this.$refs.form.validate()) {
        this.zuschauerdaten = {
          name: this.v.name ? this.v.name.trim() : '',
          vorname: this.v.vorname ? this.v.vorname.trim() : '',
          adresse: this.v.adresse ? this.v.adresse.trim() : '',
          plz: this.v.plz ? this.v.plz.trim() : '',
          ort: this.v.ort ? this.v.ort.trim() : '',
          nation: this.v.nation ? this.v.nation.trim() : '',
          telefon: this.v.telefon ? this.v.telefon.trim() : '',
          email: this.email ? this.email.trim() : '',
          aktivitaet: this.v.aktivitaet ? this.v.aktivitaet.trim() : '',
        }
      }
    },
    wettkampf_anmeldung() {
      if (this.zuschauerdaten) {
        var daten = {
          person: this.zuschauerdaten,
          geimpft: this.ggg.geimpft,
          genesen: this.ggg.genesen,
          disziplinen: this.selection,
          disziplinen_ids: this.selection_ids,
        }
        this.loading = true
        firebase
          .firestore()
          .collection('User')
          .doc(this.publicdata.verein.id)
          .collection('Veranstaltung')
          .doc(this.publicdata.wettkampf.id)
          .collection('Anmeldung')
          .add(daten)
          .then((docRef) => {
            if (this.user.data) {
              firebase
                .firestore()
                .collection('User')
                .doc(this.publicdata.verein.id)
                .collection('Anmeldungen')
                .add({
                  person: daten.person,
                  disziplinen: daten.disziplinen,
                  disziplinen_ids: daten.disziplinen_ids,
                  veranstaltung: this.publicdata.wettkampf,
                  anmeldung_id: docRef.id,
                })
            }
            firebase
              .firestore()
              .collection('email')
              .add({
                appname: this.template.var.verband,
                template: 'WETTKAMPF_ANMELDUNG',
                to: this.email,
                name: daten.person.vorname + ' ' + daten.person.nachname,
                vorname: daten.person.vorname,
                verein: this.publicdata.verein.verein.name,
                wk_name: this.publicdata.wettkampf.name,
                wk_id: this.publicdata.wettkampf.id,
                wk_anmeldung: docRef.id,
                wk_beginn:
                  this.parseDate(this.publicdata.wettkampf.beginn.datum) +
                  ' um ' +
                  this.publicdata.wettkampf.beginn.uhrzeit +
                  ' Uhr',
                domain: this.template.var.app_url,
                alias: this.$route.params.verein,
              })
              .then((mailRef) => {
                this.loading = false
                this.finished = true
              })
          })
      }
    },
    async check_in() {
      if (this.zuschauerdaten) {
        this.loading = true
        var i, j

        var sportstaette = this.publicdata.wettkampf.sportstaette

        this.publicdata.verein.sportstaetten.forEach((place) => {
          if (place.name == this.publicdata.wettkampf.sportstaette) {
            sportstaette = place
          }
        })

        var zutritt = new Zeit()
        var verlassen = new Zeit(
          this.publicdata.wettkampf.ende.datum,
          this.publicdata.wettkampf.ende.uhrzeit,
        )

        var person = {
          person: {
            vorname: this.zuschauerdaten.vorname,
            name: this.zuschauerdaten.name,
            adresse: this.zuschauerdaten.adresse,
            plz: this.zuschauerdaten.plz,
            ort: this.zuschauerdaten.ort,
            nation: this.zuschauerdaten.nation,
            telefon: this.zuschauerdaten.telefon,
          },
          ggg: this.ggg || '',
          zutritt: zutritt.toJSON(),
          weitere_personen: [],
          sportstaette: sportstaette ? sportstaette : '',
          veranstaltung: this.publicdata.wettkampf
            ? this.publicdata.wettkampf
            : '',
          verlassen: verlassen.toJSON(),
          aktivitaet: this.zuschauerdaten.aktivitaet
            ? this.zuschauerdaten.aktivitaet
            : '',
        }

        if (this.user) {
          await firebase
            .firestore()
            .collection('User')
            .doc(this.publicdata.verein.id)
            .collection('Anwesenheit')
            .add(person)
            .then((docRef) => {
              this.scrollTop()
              if (this.user.data) {
                firebase
                  .firestore()
                  .collection('User')
                  .doc(this.user.uid)
                  .collection('Besuche')
                  .add({
                    person: person.person,
                    anwesenheit: {
                      verein: this.publicdata.verein.id,
                      id: docRef.id,
                    },
                    verein: {
                      id: this.publicdata.verein.id,
                      name: this.publicdata.verein.verein.name,
                      logo: this.publicdata.verein.verein.logo,
                      alias: this.publicdata.verein.alias,
                      hygienekonzept: this.publicdata.verein.hygienekonzept
                        ? this.publicdata.verein.hygienekonzept
                        : '',
                      verband: this.publicdata.verein.verband,
                    },
                    veranstaltung: person.veranstaltung,
                    sportstaette: person.sportstaette,
                    weitere_personen: person.weitere_personen,
                    zutritt: person.zutritt,
                    verlassen: person.verlassen,
                    aktivitaet: person.aktivitaet,
                  })
                  .then((besuchRef) => {
                    firebase
                      .firestore()
                      .collection('User')
                      .doc(this.publicdata.verein.id)
                      .collection('Anwesenheit')
                      .doc(docRef.id)
                      .update({
                        user: {
                          id: this.user.uid,
                          anwesenheit: besuchRef.id,
                        },
                      })
                      .then(() => {
                        router.push('/user/tickets/' + besuchRef.id)
                      })
                      .catch((error) => {
                        this.loading = false
                      })
                  })
                  .catch((error) => {
                    console.log('Fehler', error)
                    this.loading = false
                  })
              } else {
                if (this.$route.params.sportstaette) {
                  this.scrollTop()
                  router.push(
                    '/' + this.$route.params.verein + '/sportler/' + docRef.id,
                  )
                } else {
                  this.scrollTop()
                  router.push(
                    '/' + this.$route.params.verein + '/sportler/' + docRef.id,
                  )
                }
              }
            })
            .catch((error) => {
              this.loading = false
            })
        } else {
          this.loading = false
        }
      }
    },
  },
}
</script>
